import React from "react"
import { Button } from "@material-ui/core"
import { Table } from "antd"
import moment from "moment"
import "antd/dist/antd.min.css"
import { useRecoilState } from "recoil"
import { encListState } from "../../../components/atoms/atoms"
import { CalcBilled } from "./Billed"
import { ParseCPT } from "./CPT"
import { ParsePercentPoverty } from "./PovertyLevel"
import { officialName } from "./Name"
import { CalcModifier, ParseModifier2 } from "./Modifier"
import { FormatTitle } from "./Title"
import { ExportXLS } from "../../../components/excel/ExportXLS"
import { IdentifierDTO } from "../../../dto/identifier"
import { EncounterDTO } from "../../../dto/encounter"
import { CentralHealthRow } from "../../../dto/CentralHealthRow"
import { DateTime } from "luxon"

export default function CentralHealthTable() {
  const capitalize = (s: string) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  function parseIDbyAssigner(identifiers: IdentifierDTO[], assigner: string) {
    for (let identifier of identifiers) {
      if (identifier.assigner === assigner) {
        return identifier.value;
      }
    }
    return "";
  }

  function capRender(txt: string) {
    return (
      <div
        style={{
          textTransform: "capitalize",
        }}
      >
        {txt.toLowerCase()}
      </div>
    );
  }

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (record: CentralHealthRow) => (
        <Button
          onClick={(e) => {
            onDelete(record.key, e);
          }}
          color="secondary"
          variant="contained"
        >
          Remove
        </Button>
      ),
    },
    {
      title: "DOS",
      dataIndex: "date",
      key: "date",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => moment(a.date).diff(moment(b.date)),
    },
    {
      title: "Patient Last Name",
      dataIndex: "lastname",
      key: "lastname",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.lastname.localeCompare(b.lastname),
    },
    {
      title: "Patient First Name",
      dataIndex: "firstname",
      key: "firstname",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.firstname.localeCompare(b.firstname),
    },
    {
      title: "Patient Middle Name",
      dataIndex: "middlename",
      key: "middlename",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.middlename.localeCompare(b.middlename),
    },
    {
      title: "Subscriber Address 1",
      dataIndex: "address1",
      key: "address1",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.address1.localeCompare(b.address1),
      render: capRender,
    },
    {
      title: "Subscriber Address 2",
      dataIndex: "address2",
      key: "address2",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.address2.localeCompare(b.address2),
      render: capRender,
    },
    {
      title: "Subscriber City",
      dataIndex: "city",
      key: "city",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.city.localeCompare(b.city),
      render: capRender,
    },
    {
      title: "Subscriber State",
      dataIndex: "state",
      key: "state",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.state.localeCompare(b.state),
    },
    {
      title: "Subscriber Zip Code",
      dataIndex: "zip",
      key: "zip",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.zip.localeCompare(b.zip),
    },
    {
      title: "DOB",
      dataIndex: "dob",
      key: "dob",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.dob.toString().localeCompare(b.dob.toString()),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.gender.localeCompare(b.gender),
      render: capRender,
    },
    {
      title: "MRN",
      dataIndex: "mrn",
      key: "mrn",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => parseInt(a.mrn, 10) - parseInt(b.mrn, 10)
    },
    {
      title: "Group ID",
      dataIndex: "groupid",
      key: "groupid",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => parseInt(a.groupid, 10) - parseInt(b.groupid, 10)
    },
    {
      title: "EncID/Claim Id",
      dataIndex: "eid",
      key: "eid",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => parseInt(a.eid, 10) - parseInt(b.eid, 10)
    },
    {
      title: "Provider Last Name",
      dataIndex: "pln",
      key: "pln",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.pln.localeCompare(b.pln),
    },
    {
      title: "Provider First Name",
      dataIndex: "fln",
      key: "fln",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.fln.localeCompare(b.fln),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.title.localeCompare(b.title),
    },
    {
      title: "NPI",
      dataIndex: "npi",
      key: "npi",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.npi.localeCompare(b.npi),
    },
    {
      title: "Agency",
      dataIndex: "agency",
      key: "agency",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.agency.localeCompare(b.agency),
    },
    {
      title: "Agency NPI",
      dataIndex: "agencynpi",
      key: "agencynpi",
    },
    {
      title: "TIN",
      dataIndex: "tin",
      key: "tin",
    },
    {
      title: "Agency Address 1",
      dataIndex: "agencyaddress1",
      key: "agencyaddress1",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.agencyaddress1.localeCompare(b.agencyaddress1),
      render: capRender,
    },
    {
      title: "Agency Address 2",
      dataIndex: "agencyaddress2",
      key: "agencyaddress2",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.agencyaddress2.localeCompare(b.agencyaddress2),
      render: capRender,
    },
    {
      title: "Agency City",
      dataIndex: "agencycity",
      key: "agencycity",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.agencycity.localeCompare(b.agencycity),
      render: capRender,
    },
    {
      title: "Agency State",
      dataIndex: "agencystate",
      key: "agencystate",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.agencystate.localeCompare(b.agencystate),
    },
    {
      title: "Agency Zip Code",
      dataIndex: "agencyzip",
      key: "agencyzip",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.zip.localeCompare(b.agencyzip),
    },
    {
      title: "Billed $",
      dataIndex: "billed",
      key: "billed",
    },
    {
      title: "ICD",
      dataIndex: "icd",
      key: "icd",
    },
    {
      title: "CPT",
      dataIndex: "cpt",
      key: "cpt",
      //render: cptRender
    },
    {
      title: "Modifier",
      dataIndex: "modifier",
      key: "modifier",
    },
    {
      title: "Modifier2",
      dataIndex: "modifier2",
      key: "modifier2",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.modifier2.localeCompare(b.modifier2),
    },
    {
      title: "Modifier3",
      dataIndex: "modifier3",
      key: "modifier3",
    },
    {
      title: "Modifier4",
      dataIndex: "modifier4",
      key: "modifier4",
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
    },
    {
      title: "Pos",
      dataIndex: "pos",
      key: "pos",
    },
    {
      title: "% Poverty Level",
      dataIndex: "poverty",
      key: "poverty",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => a.poverty.localeCompare(b.poverty),
      //render: povertyRender
    },
    {
      title: "SSN",
      dataIndex: "ssn",
      key: "ssn",
      sorter: (
        a:CentralHealthRow,
        b:CentralHealthRow
      ) => parseInt(a.ssn, 10) - parseInt(b.ssn, 10)
    },
  ];

  const [encList, setEncList] = useRecoilState(encListState);

  const onDelete = (
    key: number,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    const encs = encList.filter((item:EncounterDTO) => item.id !== key);
    setEncList(encs);
  };

  let xlsData: Array<string[]> = [];
  let tableData: Array<CentralHealthRow> = [];

  encList.forEach((enc: EncounterDTO) => {
    const name = officialName(enc.subject.name)
    const givens = name.given.split(" ")
    const encDate = DateTime.fromISO(enc.period.start.toString())
      .toFormat('MM-dd-yyyy')
    const dobDate = DateTime.fromISO(enc.subject.birthDate.toString())
      .toFormat('MM-dd-yyyy')

    let middle = "";
    if (givens.length > 1) {
      middle = givens[1];
    }
    const title = FormatTitle(
      enc.participant[0].individual.qualification[0].code.text
    );

    const mrn = parseIDbyAssigner(enc.subject.identifier, "Athena");
    const ssn = parseIDbyAssigner(
      enc.subject.identifier,
      "http://hl7.org/fhir/sid/us-ssn"
    );

    let icd = "";

    if (enc.diagnosis.length > 0) {
      icd = enc.diagnosis[0].condition.code.coding[0].code;
    }

    tableData.push({
      key: enc.id,
      date: encDate,
      lastname: name.family,
      firstname: givens[0],
      middlename: middle,
      address1: enc.subject.address[0].line,
      address2: "",
      city: enc.subject.address[0].city,
      state: enc.subject.address[0].state,
      zip: enc.subject.address[0].postalCode,
      dob: dobDate,
      gender: capitalize(enc.subject.gender),
      mrn: mrn,
      groupid: "53273000",
      eid: parseIDbyAssigner(enc.identifier, "Athena"),
      pln: enc.participant[0].individual.name[0].family,
      fln: enc.participant[0].individual.name[0].given,
      title: title,
      npi: parseIDbyAssigner(enc.participant[0].individual.identifier, "NPPES"),
      agency: "Volunteer Health Clinic",
      agencynpi: "",
      tin: "74-6082464",
      agencyaddress1: "4215 Medical Parkway",
      agencyaddress2: "",
      agencycity: "Austin",
      agencystate: "TX",
      agencyzip: "78756",
      billed: CalcBilled(title),
      icd: icd,
      //cpt: enc,
      cpt: ParseCPT(enc.type),
      modifier: CalcModifier(title),
      //modifier2: CalcModifier2(enc.type[0].text),
      modifier2: ParseModifier2(enc.type),
      modifier3: "",
      modifier4: "",
      units: "1",
      pos: "11",
      //poverty: enc.subject.id
      poverty: ParsePercentPoverty(enc.subject.observation),
      ssn: ssn,
    });

    xlsData.push([
      encDate,
      name.family,
      givens[0],
      middle,
      enc.subject.address[0].line,
      "",
      enc.subject.address[0].city,
      enc.subject.address[0].state,
      enc.subject.address[0].postalCode,
      dobDate,
      capitalize(enc.subject.gender),
      mrn,
      "53273000",
      parseIDbyAssigner(enc.identifier, "Athena"),
      enc.participant[0].individual.name[0].family,
      enc.participant[0].individual.name[0].given,
      title,
      parseIDbyAssigner(enc.participant[0].individual.identifier, "NPPES"),
      "Volunteer Health Clinic",
      "",
      "74-6082464",
      "4215 Medical Parkway",
      "",
      "Austin",
      "TX",
      "78756",
      CalcBilled(title),
      icd,
      ParseCPT(enc.type),
      CalcModifier(title),
      ParseModifier2(enc.type),
      "",
      "",
      "1",
      "11",
      ParsePercentPoverty(enc.subject.observation),
      ssn,
    ]);
  });

  return (
    <>
      <ExportXLS
        Data={xlsData}
        Columns={columns.slice(1).map((c) => c.title)}
        fileName={"CH"}
      />
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{ defaultPageSize: 5 }}
      />
    </>
  );
}
