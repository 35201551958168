import { CodeableConceptDTO } from "../../../dto/codeableconcept";

export const CalcModifier = (title: string) => {
  let mdRE = /^M\.?D\.?$/;
  let doRE = /^D\.?O\.?$/;
  let paRE = /^P.*A.*$/;
  let npRE = /^N.*P.*$/;
  let cnsRE = /^C.*N.*S.*$/;

  if (mdRE.test(title)) {
    return "AM";
  }
  if (doRE.test(title)) {
    return "AM";
  }
  if (paRE.test(title)) {
    return "SA";
  }
  if (npRE.test(title)) {
    return "SA";
  }
  if (cnsRE.test(title)) {
    return "SA";
  }

  return "";
};

export const CalcModifier2 = (type: string) => {
  let teleRE = /^TELE.*$/i;

  if (teleRE.test(type)) {
    return "95";
  }

  return "";
};

export const ParseModifier2 = (typeList: CodeableConceptDTO[]) => {
  let teleRE = /^TELE.*$/i;
  let mod2 = "";

  typeList.forEach((type: CodeableConceptDTO) => {
    if (teleRE.test(type.text)) {
      mod2 = "95";
    }
  });

  return mod2;
};
