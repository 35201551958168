import * as React from "react";
import { Button } from "@material-ui/core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import CenterFocusStrongIcon from "@material-ui/icons/CenterFocusStrong";
//import { CentralHealthRow } from "../../dto/CentralHealthRow"

interface ExportXLSProps {
  Data: string[][]
  Columns: string[]
  fileName:string
}

export const ExportXLS = (
  props: ExportXLSProps
  ) => {
  let xlsData: Array<string[]> = [];
  xlsData.push(props.Columns);
  for (let row of props.Data) {
    //let xlsRow: Array<string> = [];
    //for (let key of Keys) {
    //  xlsRow.push(row[key]);
    //}
    xlsData.push(row)
  }


  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xls";

  const exportToXLS = (xlsData:Array<string[]>, fileName:string) => {
    const ws = XLSX.utils.json_to_sheet(xlsData, { skipHeader: true });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "biff8", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      onClick={(e) => exportToXLS(xlsData, props.fileName)}
      color="primary"
      variant="contained"
      startIcon={<CenterFocusStrongIcon />}
    >
      Export
    </Button>
  );
};
