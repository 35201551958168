import { CodeableConceptDTO } from "../../../dto/codeableconcept";
import { CodingDTO } from "../../../dto/coding"

export const ParseCPT = (typeList: CodeableConceptDTO[]) => {
  let cpt = "";

  typeList.forEach((type: CodeableConceptDTO) => {
    if (!(typeof type.coding === "undefined")) {
      type.coding.forEach((coding: CodingDTO) => {
        if (coding.system === "http://www.ama-assn.org/go/cpt") {
          cpt = coding.code;
        }
      });
    }
  });

  return cpt;
};
