import React, { useState } from "react"
import { useFetch } from "./fetch/Fetch"
import { PatientDTO } from "../../dto/patient"

interface PatientDashboardProps {
  aid: string;
}

export default function PatientDashboard(
  props: PatientDashboardProps ){

    const [response, loading, hasError] = useFetch({
      url:"patient/athena/" + props.aid
    })

    let pid = response.id

    return (
        <>
          The AID is {props.aid}
          and the response is {JSON.stringify(response)}
          The response id is {response.id}
        </>
    )
}