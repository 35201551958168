import { HumanNameDTO } from "../../../dto/humanname";

export const officialName = (names: HumanNameDTO[]) => {
    let nm = names[0]

    for (let name of names) {
      if (name.use === 'official') {
        nm = name
      }
    }

    return nm
}
