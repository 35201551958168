import React, { Component } from "react"
import { AxiosError } from "axios"
import HumanNameTable from "../components/HumanNameTable"
import { Paper, TextField, IconButton, Typography } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import { HumanNameDTO } from "../dto/humanname"
import ReloadButton from "../../auth/components/ReloadButton"
import { getToken } from "../../auth/cognito/access"
import { NCapi } from "../../api/axios/NCapi"
import PatientDashboard from "./components/PatientDashboard"
//import { useNCapi } from "../../api/fetch/use"

interface PatientSearchProps {}

interface PatientSearchState {
  AthenaID: string;
  FirstName: string;
  LastName: string;
  DOB: Date;
  list: HumanNameDTO[];
  error: AxiosError;
  isAthena: boolean;
  isError: boolean;
  isLoading: boolean;
  isDOB: boolean;
}

class PatientSearch extends Component<PatientSearchProps, PatientSearchState> {
  state: Readonly<PatientSearchState> = {
    AthenaID: "",
    FirstName: "",
    LastName: "",
    DOB: new Date(),
    list: [],
    error: Object(),
    isAthena: false,
    isLoading: false,
    isError: false,
    isDOB: false,
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value,
    });
  };

  patientSearch = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.setState({ isLoading: true })

    getToken().then((token) => {
      //alert('Token: ' + token)
      NCapi.defaults.headers.common["CognitoJWT"] = token
      if (this.state.AthenaID.length > 0) {
        this.setState({
          isAthena: true,
        })
        //this.AthenaSearch()
      } else {
        this.NameDobSearch()
      }
    }).catch((error) =>
      this.setState({
        error,
        isError: true,
        isLoading: false,
      })
    )

    event.preventDefault()

  }

  AthenaSearch = () => {


        NCapi.get("patient/athena/" + this.state.AthenaID).then((response) => {
            if (response.data.length < 1) {
              alert("The Athena search returned no results.")
            }
            this.setState({
              list: [response.data],
              isLoading: false,
            })
          })
          .catch((error) =>
            this.setState({
              error,
              isError: true,
              isLoading: false,
            })
          );

  };

  NameDobSearch = () => {

        NCapi.post("humanname", {
          given: this.state.FirstName,
          family: this.state.LastName,
          patient: {
            birthDate: this.state.DOB,
          },
        })
          .then((response) => {
            if (response.data.length < 1) {
              alert("The Name and DOB search returned no results.")
            }
            this.setState({
              list: response.data,
              isLoading: false,
            })
          })
          .catch((error) =>
            this.setState({
              error,
              isError: true,
              isLoading: false,
            })
          );
  };

  render() {
    if (this.state.isError) {
      return (
        <div>
          <Paper>
            <Typography variant="h2">
              Patient Search Error: {this.state.error.message}
            </Typography>
            <ReloadButton />
          </Paper>
        </div>
      );
    }

    if (this.state.isAthena) {
      return <PatientDashboard aid={this.state.AthenaID} />;
    }

    if (this.state.list.length > 0) {
      return <HumanNameTable humannames={this.state.list} />;
    }

    return (
      <div>
        <Paper>
          <Typography variant="h5">Patient Search</Typography>
          <TextField
            placeholder="Athena ID"
            fullWidth
            margin="normal"
            name="AthenaID"
            value={this.state.AthenaID}
            onChange={this.handleChange}
          />
          <TextField
            placeholder="First Name"
            fullWidth
            margin="normal"
            name="FirstName"
            value={this.state.FirstName}
            onChange={this.handleChange}
          />
          <TextField
            placeholder="Last Name"
            fullWidth
            margin="normal"
            name="LastName"
            value={this.state.LastName}
            onChange={this.handleChange}
          />
          <TextField
            name="DOB"
            value={this.state.DOB}
            onChange={this.handleChange}
            id="date"
            label="Birthday"
            type="date"
            defaultValue="1900-01-01"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <IconButton onClick={this.patientSearch}>
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
    );
  }
}

export default PatientSearch;
