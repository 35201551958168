import { HumanNameDTO } from "./humanname";
import { ObservationDTO } from "./observation";
import { IdentifierDTO } from "./identifier";
import { AddressDTO } from "./address";

export enum AdministrativeGender {
  MALE = "male",
  FEMALE = "female",
  OTHER = "other",
  UNKNOWN = "unknown",
}

export interface PatientDTO {
  id: number;
  name: HumanNameDTO[];
  identifier: IdentifierDTO[];
  address: AddressDTO[];
  observation: ObservationDTO[];
  birthDate: Date;
  gender: AdministrativeGender;
}
