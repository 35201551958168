import { AxiosRequestConfig } from "axios"
import { useEffect, useState } from "react"
import { NCapi } from "../../../../api/axios/NCapi"
import { getToken } from "../../../../auth/cognito/access"
import { AdministrativeGender, PatientDTO } from "../../../dto/patient"

export function useFetch(config: AxiosRequestConfig)
  :[PatientDTO, boolean, boolean]  {
    const [response, setResponse] = useState<PatientDTO>({
      id:0,
      name: [],
      identifier: [],
      address: [],
      observation: [],
      birthDate: new Date(),
      gender: AdministrativeGender.UNKNOWN,
    })
    const [loading, setLoading] = useState(false)
    const [hasError, setHasError] = useState(false)
    useEffect(() => {
      setLoading(true)
      getToken()
      .then((token) => {
        //alert('Token: ' + token)
        NCapi.defaults.headers.common["CognitoJWT"] = token
        NCapi(config)
        .then((res) => {
          setResponse(res.data)
          setLoading(false)
        }).catch(() => {
          setHasError(true)
          setLoading(false)
        })
      })
    }, [ config ])
    return [ response, loading, hasError ]
}