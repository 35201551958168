import { ObservationDTO } from "../../../dto/observation";
import { CodingDTO } from "../../../dto/coding";

export const ParsePercentPoverty = (obsList: ObservationDTO[]) => {
  let percent = '0';

  obsList.forEach((obs: ObservationDTO) => {
    if (!(typeof obs.code === "undefined")) {
      if (!(typeof obs.code.coding === "undefined")) {
        obs.code.coding.forEach((coding: CodingDTO) => {
          if (coding.code === "74185-0") {
            percent = obs.valueString;
          }
        });
      }
    }
  });

  return percent;
};
